import React from "react"
import { graphql } from "gatsby"
import Layout from "../component/layout"
import Meta from "../component/meta"

export default ({ data }) => {
  const post = data.markdownRemark;
  const baseUrl = data.site.siteMetadata.url;
  const url = baseUrl + '' + post.fields.slug;
  return (
    <React.Fragment>
      <Meta
        title={post.frontmatter.title}
        url={url}
        lang={post.frontmatter.lang}
        keywords={post.frontmatter.tags.split(',')}></Meta>
      <Layout comments={true} url={url} language={post.frontmatter.lang}>
        <div>
          <h1>{post.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
        <h6>{post.frontmatter.date}, {post.frontmatter.tags}</h6>
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        url
      }
    },
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html,
      fields{
        slug
  	  },
      frontmatter {
        title,
        tags,
        date(formatString: "DD-MM-YYYY"),
        lang
      }
    }
  }
`
